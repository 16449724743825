import { AdhocTextInterface, ConnectorDetailsInterface } from "./types";
import React from "react";
import { PricingResponse } from "./pricing";

export interface ContextInterface {
  evseID?: string;
  sessionID?: string;
  title?: string;
  price?: PricingResponse;
  connector?: ConnectorDetailsInterface;
  connectorUpdatedAt?: Date;
  adhocText?: AdhocTextInterface;
}

export const AppCtx = React.createContext<ContextInterface | null>(null);

export default function CreateCtx<ContextInterface extends {} | null>() {
  const ctx = React.createContext<ContextInterface | undefined>(undefined);

  function useCtx() {
    const c = React.useContext(ctx);
    if (c === undefined) throw new Error("useCtx must be inside a Provider with a value");
    return c;
  }

  return [useCtx, ctx.Provider] as const; // 'as const' makes TypeScript infer a tuple
}
