import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import ReCaptcha from "../commom/recaptcha";
import InfoPopup from "../popups/info.popup";
import CheckoutText from "./checkout.text";
import FailAlert from "./fail.alert";
import { ConnectorStatus, NewSessionRequestInterface } from "../commom/types";
import { AppCtx } from "../commom/app.context";
import { CustomRouterProps } from "../commom/props";

const CheckoutForm: React.FC<CustomRouterProps> = ({ evseID }) => {
  const { t } = useTranslation(["common", "checkout"]);
  const appCtx = useContext(AppCtx);
  const [loading, setLoading] = useState<boolean>(false);
  const [fail, setFail] = useState<boolean>(false);
  const [failMessage, setFailMessage] = useState<string>("");
  const [captchaToken, setCaptchaToken] = useState<string>("");
  const [cookies, setCookie] = useCookies(["session_info"]);

  const [connectorAvailable, setConnectorAvailable] = useState<boolean>(false);

  useEffect(() => {
    if (!cookies.session_info?.sessionID) {
      return;
    }
    if (appCtx?.connector?.connectorStatus === ConnectorStatus.Charging) {
      return;
    }
    setCookie("session_info", undefined, { path: "/" });
  }, [evseID]);

  const handleCheckout = async (e: React.MouseEvent<HTMLButtonElement>, execute: () => Promise<string>) => {
    e.preventDefault();
    setLoading(true);
    const token = await execute();
    setLoading(false);
    setCaptchaToken(token);

    if (evseID) {
      processCheckout();
    }
  };

  useEffect(() => {
    if (!evseID) {
      return;
    }
    if (!connectorAvailable) {
      return;
    }

    const createPaymentLink: NewSessionRequestInterface = {
      captchaToken: captchaToken,
      evseID: evseID
    };

    axios
      .post(`/api/evse/${evseID}/create-payment/`, {
        request: createPaymentLink
      })
      .then((response) => {
        if (response.data?.status === false || !response.data?.redirectURL || response.data?.redirectURL === "") {
          throw new Error("Error creating payment link");
        }
        navigate(response.data?.redirectURL);
        return;
      })
      .catch(() => {
        setLoading(false);
        setFail(true);
        setFailMessage(t("checkout:server_error"));
      });
    setConnectorAvailable(false);
  }, [evseID, connectorAvailable]);

  const processCheckout = () => {
    if (!evseID) {
      return;
    }
    axios
      .get(`/api/evse/${evseID}/get-connector-details/`)
      .then((response) => {
        const connectorDetails = response.data;
        if (
          connectorDetails.connectorStatus !== ConnectorStatus.Available &&
          connectorDetails.connectorStatus !== ConnectorStatus.Preparing
        ) {
          setFailMessage(t("checkout:charge_point_unavailable"));
          setConnectorAvailable(false);
          setLoading(false);
          setFail(true);
          return;
        }

        setConnectorAvailable(true);
        return;
      })
      .catch(() => {
        setFailMessage(t("checkout:server_error"));
        setConnectorAvailable(false);
        setLoading(false);
        setFail(true);
      });
  };

  return (
    <ReCaptcha>
      {(captcha: { execute: () => Promise<string>; isReady: boolean }) => (
        <form>
          <div>
            <CheckoutText />

            <input type="hidden" value={captchaToken || ""} onChange={() => {}} id="recaptcha-key" name="g-recaptcha-response" />

            <input type="hidden" id="token" name="token" />

            {fail && <FailAlert message={failMessage} />}

            {/*Bottom Nav*/}
            <div className="flex justify-between">
              <Link type="button" to="../">
                <button
                  type="button"
                  className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <svg
                    className="w-3 h-3 text-white ml-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"
                    />
                  </svg>
                  {t("common:back")}
                </button>
              </Link>

              <InfoPopup />

              {cookies.session_info?.sessionID && cookies.session_info.evseID === evseID ? (
                <Link
                  type="button"
                  title="Session"
                  to={`../success/session/${cookies.session_info.sessionID}`}
                  className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {t("common:session_details")} &nbsp;
                  <svg
                    className="w-3 h-3 text-white mr-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                    />
                  </svg>
                </Link>
              ) : (
                <button
                  type="button"
                  onClick={(e) => handleCheckout(e, captcha.execute)}
                  disabled={!captcha.isReady}
                  className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {t("checkout:load")} &nbsp;
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                  ) : (
                    <svg
                      className="w-3 h-3 text-white mr-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                      />
                    </svg>
                  )}
                </button>
              )}
            </div>
          </div>
        </form>
      )}
    </ReCaptcha>
  );
};

export default CheckoutForm;
