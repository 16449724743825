import React, { useEffect } from "react";
import { ConnectorStatus, paramProps } from "../commom/types";
import L from "leaflet";
import axios from "../utils/axios";

const greenIcon = L.icon({
  iconUrl: window.location.origin + "/marker_available.png",
  iconSize: [42, 42], // size of the icon
  shadowSize: [50, 64] // size of the shadow
});
const redIcon = L.icon({
  iconUrl: window.location.origin + "/marker_offline.png",
  iconSize: [42, 42] // size of the icon
});
const blueIcon = L.icon({
  iconUrl: window.location.origin + "/marker_charging.png",
  iconSize: [42, 42], // size of the icon
  shadowSize: [50, 64] // size of the shadow
});
const greyIcon = L.icon({
  iconUrl: window.location.origin + "/marker_unknown.png",
  iconSize: [42, 42], // size of the icon
  shadowSize: [50, 64] // size of the shadow
});

const MapComponent: React.FC<paramProps> = ({ evseID }) => {
  useEffect(() => {
    if (!evseID) {
      return;
    }

    axios
      .get(`/api/evse/${evseID}/get-map-details/`)
      .then((response) => {
        const mapDetails = response.data;

        const latitude = mapDetails.lat;
        const longitude = mapDetails.long;

        const map = L.map("map").setView([latitude, longitude], 13);

        L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          className: "map-tiles"
        }).addTo(map);

        switch (mapDetails?.status) {
          case ConnectorStatus.Available:
          case ConnectorStatus.Preparing:
            L.marker([latitude, longitude], { icon: greenIcon }).addTo(map);
            break;
          case ConnectorStatus.Offline:
          case ConnectorStatus.Unavailable:
            L.marker([latitude, longitude], { icon: redIcon }).addTo(map);
            break;
          case ConnectorStatus.Charging:
          case ConnectorStatus.Reserved:
          case ConnectorStatus.Finishing:
            L.marker([latitude, longitude], { icon: blueIcon }).addTo(map);
            break;

          default:
            L.marker([latitude, longitude], { icon: greyIcon }).addTo(map);
            break;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [evseID]);

  return (
    <>
      <div id="map" className="rounded" />
    </>
  );
};

export default MapComponent;
