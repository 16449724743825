import React, { useContext, useEffect, useState } from "react";
import { ConnectorStatus, paramProps } from "../commom/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChargingStation } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { AppCtx } from "../commom/app.context";
import moment from "moment";
import "moment/locale/de";
import { TFunction, useTranslation } from "react-i18next";
import "../index.css";

const HeaderComponent: React.FC<paramProps> = ({ evseID }) => {
  const appCtx = useContext(AppCtx);

  const MINUTE_MS = 60000;

  const [lastUpdated, setLastUpdated] = useState("");

  const { t, i18n } = useTranslation(["header", "status"]);

  i18n.on("languageChanged", () => {
    setLastUpdatedTime();
  });

  const setLastUpdatedTime = () => {
    const dateTimeAgo = moment(appCtx?.connectorUpdatedAt).locale(i18n.language);

    if (i18n.language.toLowerCase().includes("en")) {
      setLastUpdated(dateTimeAgo.fromNow());

      setInterval(() => {
        setLastUpdated(dateTimeAgo.fromNow());
      }, MINUTE_MS);
    } else {
      setLastUpdated(dateTimeAgo.fromNow() + " ");

      setInterval(() => {
        setLastUpdated(dateTimeAgo.fromNow());
      }, MINUTE_MS);
    }
  };
  useEffect(() => {
    setLastUpdatedTime();
  }, [evseID]);

  return (
    <div className="mt-5 px-2">
      <div className="flex flex-col gap-5">
        <div className="text-gray-900 dark:text-gray-200">
          <div className="inline-flex text-2xl font-bold items-center w-full">
            <span className={appCtx?.connector ? "" : "hidden"}>
              <FontAwesomeIcon icon={faChargingStation} /> &nbsp;
            </span>
            <h2>{appCtx?.connector?.stationName || <Skeleton className="animate-pulse" />} </h2>
          </div>
        </div>

        <div className="flex flex-row xs:flex-wrap">
          <div className="flex gap-1 flex-col w-3/4">
            <h5 className="text-xl font-medium text-gray-900 dark:text-white">
              {t("header:connector")} {appCtx?.connector?.port || <Skeleton className="animate-pulse" />}
            </h5>

            <span className="text-sm text-gray-500 dark:text-gray-400">
              {appCtx?.connector?.connectorId || <Skeleton className="animate-pulse" />}
            </span>

            <p className="font-normal text-gray-500 dark:text-gray-400">
              {appCtx?.connector?.cpoName || <Skeleton className="animate-pulse" />}
            </p>
          </div>

          <div className={"flex-col gap-1 justify-center items-center w-1/3"}>
            <div className="grid pb-2">
              {convertStatus(t, appCtx?.connector?.connectorStatus) || <Skeleton className="animate-pulse" />}
            </div>
            {!lastUpdated || (
              <span
                title={lastUpdated}
                className="w-28 py-1.5 text-gray-800 text-xs inline-flex items-center rounded mr-2 dark:text-gray-400"
              >
                <svg aria-hidden="true" className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                {lastUpdated}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeaderComponent;

function convertStatus(t: TFunction<("header" | "status")[], undefined>, status: ConnectorStatus | undefined) {
  if (status === undefined) {
    return (
      <span className="bg-gray-100 text-gray-800 text-sm font-medium  w-28 py-1.5 text-center  rounded">
        <Skeleton className="animate-pulse" />
      </span>
    );
  }
  switch (status) {
    case "Offline":
    case "Unavailable":
      return (
        <span className="bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 text-sm font-medium w-24 mr-2 text-center px-2.5 py-0.5 rounded ">
          {translateStatus(t, status)}
        </span>
      );

    case "Available":
    case "Preparing":
      return (
        <span className="bg-[#77c14e] text-gray-900 text-sm font-medium w-24 mr-2 text-center px-2.5 py-0.5 rounded ">
          {translateStatus(t, status)}
        </span>
      );

    case "Charging":
    case "Reserved":
    case "Finishing":
      return (
        <span className="bg-indigo-100 text-indigo-800 dark:bg-indigo-200 dark:text-indigo-900 text-sm font-medium w-24 mr-2 text-center px-2.5 py-0.5 rounded ">
          {translateStatus(t, status)}
        </span>
      );

    default:
      return (
        <span className="bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300 text-sm font-medium w-24 mr-2 text-center px-2.5 py-0.5 rounded ">
          {translateStatus(t, status)}
        </span>
      );
  }
}

function translateStatus(t: TFunction<("header" | "status")[], undefined>, status: ConnectorStatus) {
  switch (status) {
    case ConnectorStatus.Available:
    case ConnectorStatus.Preparing:
      return t("status:available");

    case ConnectorStatus.Charging:
    case ConnectorStatus.Reserved:
    case ConnectorStatus.Finishing:
    case ConnectorStatus.SuspendedEV:
    case ConnectorStatus.SuspendedEVSE:
      return t("status:occupied");

    case ConnectorStatus.Offline:
    case ConnectorStatus.Unavailable:
      return t("status:offline");

    case ConnectorStatus.EVCommunicationError:
      return t("status:communication_error");
  }
}
