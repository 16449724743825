import axios from "axios";
import configData from "../config.json";

const axiosServices = axios.create();

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //
const goURL: string = configData.SERVER_URL as string;

// set the base URL for the axios instance
axiosServices.defaults.baseURL = goURL;

// set headers for the axios instance
axiosServices.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";
axiosServices.defaults.headers.common["Accept"] = "application/json";
axiosServices.defaults.headers.common["Authorization"] = "key=" + configData.AUTH_KEY;

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || "Wrong Services")
);

export default axiosServices;
