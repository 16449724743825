import React, { useEffect, useState } from "react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { EndSessionStatus, PaymentStatus, SessionStatus, Stages } from "../commom/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderComponent from "../components/loader.component";
import { TFunction, useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { faSpinner, faStop } from "@fortawesome/free-solid-svg-icons";
import axios from "../utils/axios";

const SuccessPage: React.FC<any> = ({ evseID, sessionID }) => {
  const { t } = useTranslation(["common", "success"]);

  const [stage, setStage] = useState<Stages | undefined>();

  const [showStopButton, setShowStopButton] = useState(true);
  const [showStopMessage, setShowStopMessage] = useState(false);

  // create a cookie to store session id and evse id if the session status is active. cookie will be deleted when the session is inactive and after 24 hours
  const [cookie, setCookie] = useCookies(["session_info"]);

  const [remoteStopLoader, setRemoteStopLoader] = useState(false);
  const [remoteStopError, setRemoteStopError] = useState(false);

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(61);

  useEffect(() => {
    axios.get(`/api/evse/${evseID}/payment-success/${sessionID}/`).then((response) => {
      setStage(response.data);
    });
  }, []);

  useEffect(() => {
    let repeat = 25;
    let checkingInterval = setInterval(() => {
      if (repeat === 0) {
        clearInterval(checkingInterval);
        return;
      }
      repeat--;

      axios.get(`/api/evse/${evseID}/payment-success/${sessionID}/`).then((response) => {
        setStage(response.data);

        if (
          response.data.payment?.status === PaymentStatus.Declined ||
          response.data.payment?.status === PaymentStatus.Expired ||
          response.data.payment?.status === PaymentStatus.Rejected ||
          response.data.session?.status === SessionStatus.Active ||
          response.data.session?.status === SessionStatus.Finished ||
          response.data.session?.status === SessionStatus.Invalid ||
          response.data.session?.status === SessionStatus.Error
        ) {
          clearInterval(checkingInterval);
          return;
        }

        if (response.data.session.status === SessionStatus.Active) {
          setCookie("session_info", { evseID, sessionID }, { path: "/", maxAge: 86400 });
        } else if (cookie.session_info !== undefined) {
          setCookie("session_info", undefined, { path: "/" });
        }
      });
    }, 5000);
  }, []);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const remoteStop = async () => {
    setRemoteStopError(false); // hide error if any
    setRemoteStopLoader(true); // show the loader while the request is being processed

    axios
      .get(`/api/evse/${evseID}/end-session/${sessionID}/`)
      .then((response) => {
        setRemoteStopLoader(false);

        if (response.data.remoteStop.status === EndSessionStatus.Ended) {
          setStage(response.data);
          triggerSuccess();
        } else {
          triggerFailed();
        }
      })
      .catch((error) => {
        triggerFailed();
      });
  };

  const triggerSuccess = () => {
    setShowStopMessage(true);
    setRemoteStopError(false);
    setShowStopButton(false);
  };

  const triggerFailed = () => {
    setRemoteStopError(true);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{evseID} Status</title>
        </Helmet>
        <ol className="relative border-l border-gray-200 dark:border-gray-700 mt-10">
          {/*show payment status */}
          {stage?.payment.display ? (
            <li className="mb-10 ml-6">
              <span className="flex absolute -left-3 justify-center items-center w-6 h-6  dark:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                  />
                </svg>
              </span>

              <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white"> {t("success:payment_status")}</h3>

              <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                {translatePaymentStatus(t, stage?.payment?.status)}
              </p>
            </li>
          ) : (
            ""
          )}
          {/*when the payment status is authorized and session status is Pending show alert to connect the vehicle*/}
          <li
            className={
              stage?.payment?.status === PaymentStatus.Authorized && stage?.session?.status === SessionStatus.Pending
                ? "mb-10 ml-6"
                : "hidden"
            }
          >
            <span className="flex absolute -left-3 justify-center items-center w-6 h-6 dark:text-white">
              <svg className="h-6 w-6" width="24" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M7 7h10v6a3 3 0 0 1 -3 3h-4a3 3 0 0 1 -3 -3v-6" />
                <line x1="9" y1="3" x2="9" y2="7" />
                <line x1="15" y1="3" x2="15" y2="7" />
                <path d="M12 16v2a2 2 0 0 0 2 2h3" />
              </svg>
            </span>
            <h3 className="mb-1 text-lg font-semibold text-gray-900 dark:text-white">{t("common:charge_point_status")}</h3>
            <p className="text-base font-normal text-gray-500 dark:text-gray-400">
              {t("success:connect_your_vehicle")}
              <span className="dark:text-white flex flex-wrap items-center justify-center mt-10 mr-10">
                {minutes === 0 && seconds === 0 ? null : (
                  <span className="countdown text-6xl"> {seconds < 10 ? `0${seconds}` : seconds}</span>
                )}
              </span>
            </p>
          </li>

          <li className={stage?.payment?.status === PaymentStatus.Success || stage?.session?.status ? "mb-10 ml-6" : "hidden"}>
            <span className="flex absolute -left-3 justify-center items-center w-6 h-6 dark:text-white">
              <svg
                className="h-8 w-8 "
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 18H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3.19M15 6h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3.19" />{" "}
                <line x1="23" y1="13" x2="23" y2="11" /> <polyline points="11 6 7 12 13 12 9 18" />
              </svg>
            </span>
            <h3 className="mb-1 text-lg font-semibold text-gray-900 dark:text-white">{t("success:charging_status")}</h3>
            <p className="text-base font-normal text-gray-500 dark:text-gray-400">
              {translateTransactionStatus(t, stage?.session?.status)}
            </p>
          </li>

          {showStopMessage ? (
            <li className={stage?.payment?.status === PaymentStatus.Success ? "mb-10 ml-6" : "hidden"}>
              <span className="flex absolute -left-3 justify-center items-center w-6 h-6 dark:text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z"
                  />
                </svg>
              </span>
              <h3 className="mb-1 text-lg font-semibold text-gray-900 dark:text-white">{t("success:charging_status")}</h3>
              <p className="text-base font-normal text-gray-500 dark:text-gray-400">{t("success:session.stopped_successfully")}</p>
            </li>
          ) : (
            ""
          )}
        </ol>

        <div className={stage?.payment.status === PaymentStatus.Pending ? "flex justify-center py-10" : "hidden"}>
          <div className="loader">
            <LoaderComponent />
          </div>
        </div>

        {stage?.session?.status === SessionStatus.Active && showStopButton && !showStopMessage ? (
          <div className="mt-5">
            <div className={""}>
              <button
                type="button"
                onClick={remoteStop}
                className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {t("success:session.end_session")}
                &nbsp;
                <FontAwesomeIcon icon={remoteStopLoader ? faSpinner : faStop} className={remoteStopLoader ? "fa-spin" : ""} />
              </button>
            </div>
          </div>
        ) : (
          ""
        )}

        {remoteStopError ? (
          <div className="mt-5 p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
            <span className="font-medium"> {t("success:session.stopped_error")} </span>
            {t("success:session.stopped_error_description")}
          </div>
        ) : (
          ""
        )}
      </HelmetProvider>
    </>
  );
};
export default SuccessPage;

function translatePaymentStatus(t: TFunction<("common" | "success")[], undefined>, status?: PaymentStatus) {
  switch (status) {
    case undefined:
      return t("success:payment.processing");
    case PaymentStatus.Success:
    case PaymentStatus.Authorized:
      return t("success:payment.succeeded");
    case PaymentStatus.Expired:
      return t("success:payment.expired");
    case PaymentStatus.Declined:
      return t("success:payment.declined");
    case PaymentStatus.Pending:
      return t("success:payment.pending");
    case PaymentStatus.Rejected:
      return t("success:payment.rejected");
  }
}

function translateTransactionStatus(t: TFunction<("common" | "success")[], undefined>, status?: SessionStatus) {
  switch (status) {
    case undefined:
      return t("success:session.processing");
    case SessionStatus.Active:
      return t("success:session.charge_point_unlocked");
    case SessionStatus.Pending:
      return t("success:session.pending");
    case SessionStatus.Finished:
      return t("success:session.finished");
    case SessionStatus.Invalid:
      return t("success:session.invalid");
    case SessionStatus.Error:
      return t("success:session.error");
  }
}
