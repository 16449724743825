export interface PricingResponse {
  status: number;
  content: TariffObj | undefined;
  error: ErrorObj | undefined;
}

export interface ErrorObj {
  message: string;
  code: number;
}

export interface TariffObj {
  tariff_id: number;
  vat: number;
  name?: string;
  valid_until: string;
  invoice_fee?: number; // in cents for uid_groups entities
  all_prices: PriceObj[];
  active_price: ActivePriceObj;
}

export interface PriceObj {
  type: string;
  price: number;
  step_size: number;
  day_restrictions?: DayRestriction[];
  time_restriction?: TimeRestrictionObj;
  duration_restriction?: DurationRestrictionObj;
}

export interface DayRestriction {
  day_of_week: DayOfWeek;
}

export interface ActivePriceObj {
  flat_price: PriceDetails;
  energy_price: PriceDetails;
  blocking_fees: PriceDetails;
  time_prices: PriceDetails[];
}

export interface PriceDetails {
  price: number;
  start_time?: string;
  stop_time?: string;
  min_duration?: string;
  max_duration?: string;
}

export enum DayOfWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY"
}

type TimeRestrictionObj = {
  start_time: string;
  stop_time: string;
};

type DurationRestrictionObj = {
  min_duration?: string;
  max_duration?: string;
};
