import React from "react";

const LinearLoader: React.FC = () => {
  return (
    <div className="w-full h-2 bg-gray-300 text-center">
      <div className="h-2 bg-blue-500 animate-pulse"></div>
    </div>
  );
};

export default LinearLoader;
