import React, { useEffect, useState } from "react";
import { DesignInterface, paramProps } from "../commom/types";
import { Link } from "@reach/router";
import axios from "../utils/axios";

const TopComponent: React.FC<paramProps> = ({ evseID }) => {
  const [design, setDesign] = useState<DesignInterface>();

  useEffect(() => {
    if (!evseID) {
      return;
    }

    axios.get(`/api/evse/${evseID}/get-design/`).then((response) => {
      setDesign(response.data);
    });
  }, [evseID]);

  return (
    <>
      <div className="dark:hidden block max-w-lg border-gray-200 px-2 sm:px-4 py-2.5 rounded">
        <div className="container flex flex-wrap items-left mx-auto">
          {design?.darkLogo ? (
            <div className="">
              <Link to={"/con/" + evseID} className="">
                <img id="logo" className="main-logo" src={design?.darkLogo} alt={design?.cpoName} title={design?.cpoName} />
              </Link>
            </div>
          ) : (
            <span className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {design?.cpoName === "" ? "Adhoc-Charge" : design?.cpoName}
            </span>
          )}
        </div>
      </div>

      <div className="hidden dark:block max-w-lg px-2 sm:px-4 py-2.5 rounded ">
        <div className="container flex flex-wrap items-left mx-auto">
          {design?.lightLogo ? (
            <div className="">
              <Link to={"/con/" + evseID} className="">
                <img id="logo" className="main-logo" src={design?.lightLogo} alt={design?.cpoName} title={design?.cpoName} />
              </Link>
            </div>
          ) : (
            <span className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {design?.cpoName === "" ? "Adhoc-Charge" : design?.cpoName}
            </span>
          )}
        </div>
      </div>
    </>
  );
};
export default TopComponent;
