export interface CSRFToken {
  token: string;
}

export interface ConnectorDetailsInterface {
  connectorId: string;
  connectorStatus: ConnectorStatus;
  connectorPlugType: string;
  connectorPower: number;
  connectorAmpere: number;
  stationName: string;
  cpoName: string;
  port: number;
}

export interface AdhocTextInterface {
  en: PopupTextInterface;
  de: PopupTextInterface;
}

export interface PopupTextInterface {
  info: string;
  agb: string;
  privacy: string;
}

export enum ConnectorStatus {
  Offline = "Offline",
  Unavailable = "Unavailable",
  Available = "Available",
  Preparing = "Preparing",
  Charging = "Charging",
  Reserved = "Reserved",
  Finishing = "Finishing",
  SuspendedEV = "SuspendedEV",
  SuspendedEVSE = "SuspendedEVSE",
  EVCommunicationError = "EVCommunicationError",
  Faulted = "Faulted"
}

export interface DesignInterface {
  cpoName: string;
  lightLogo: string;
  darkLogo: string;
  stripeEn: PopupTextInterface;
  stripeDe: PopupTextInterface;
}

export interface MainDetailsInterface {
  street: string;
  postCode: number;
  houseNumber: string;
  city: string;
  country: string;
  phone: string;
  lat: number;
  long: number;
}

export interface Stages {
  sessionID: string;
  timeStamp: string;
  payment: Payment;
  session: Session;
  remoteStop: RemoteStop;
}

export interface Payment {
  display: boolean;
  status: PaymentStatus;
  timeStamp: string;
}

export interface Session {
  status: SessionStatus;
}

export interface RemoteStop {
  error: string;
  status: SessionStatus;
  timeStamp: string;
}

export enum PaymentStatus {
  Success = "success",
  Declined = "declined",
  Expired = "expired",
  Authorized = "authorized",
  Pending = "pending",
  Rejected = "rejected"
}

export enum EndSessionStatus {
  Ended = "ended",
  Errored = "errored"
}

export enum SessionStatus {
  Active = "Active",
  Pending = "Pending",
  Finished = "Finished",
  Invalid = "Invalid",
  Error = "Error"
}

export interface MapDetailsInterface {
  lat: number;
  long: number;
  status: ConnectorStatus;
}

export interface NewSessionRequestInterface {
  captchaToken: string;
  evseID: string;
}

export interface NewSessionResponseInterface {
  status: string;
  message: string;
  redirectURL: string;
}

export interface paramProps {
  evseID: any;
}
