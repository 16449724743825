import React, { useContext, useEffect } from "react";

import { Link } from "@reach/router";
import PriceComponent from "./../components/price.component";
import InfoPopup from "../popups/info.popup";
import { AppCtx } from "../commom/app.context";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Map from "../components/map.component";
import { useTranslation } from "react-i18next";
import { MainDetailsInterface } from "../commom/types";
import axios from "../utils/axios";
import { CustomRouterProps } from "../commom/props";

const DetailsPage: React.FC<CustomRouterProps> = ({ evseID }) => {
  const [data, setData] = React.useState<MainDetailsInterface>();

  const appCtx = useContext(AppCtx);

  useEffect(() => {
    if (!evseID) {
      return;
    }
    axios
      .get(`/api/evse/${evseID}/get-location-details/`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  }, [evseID]);

  const { t } = useTranslation(["common", "details", "price"]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {evseID} {t("details:title")}
          </title>
        </Helmet>

        <div className="flex flex-col gap-4 py-5 tracking-tight text-gray-900 dark:text-gray-200">
          <Map evseID={evseID} />

          <div className="tracking-tight text-gray-900 dark:text-gray-200">
            <div className="inline-flex gap-4 items-center w-full">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              {data?.street !== undefined ? (
                data?.street + "  " + data?.houseNumber
              ) : (
                <div className="w-40">
                  <Skeleton className="animate-pulse" count={2} />
                </div>
              )}
              <br />
              {data?.postCode} {data?.city}
            </div>
          </div>

          <div className="tracking-tight text-gray-900 dark:text-gray-200">
            <div className="inline-flex gap-4  items-center w-full">
              <svg className="h-6 w-6" width="24" viewBox="4 1 22 22" strokeWidth={2} stroke="currentColor" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M7 7h10v6a3 3 0 0 1 -3 3h-4a3 3 0 0 1 -3 -3v-6" />
                <line x1="9" y1="3" x2="9" y2="7" />
                <line x1="15" y1="3" x2="15" y2="7" />
                <path d="M12 16v2a2 2 0 0 0 2 2h3" />
              </svg>
              {appCtx?.connector?.connectorPlugType !== undefined ? (
                <div>
                  <span className="mr-2">{appCtx?.connector?.connectorPlugType}</span>
                  <span className="wx-4">{appCtx?.connector?.connectorPower}kW</span>
                  <span className="mx-2">({appCtx?.connector?.connectorAmpere}A)</span>
                </div>
              ) : (
                <div className="w-40 ml-2">
                  <Skeleton className="animate-pulse" />
                </div>
              )}
            </div>
          </div>
          <div className="tracking-tight text-gray-900 dark:text-gray-200">
            <div className="inline-flex gap-4  items-center w-full">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="-2 0 28 22" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              {data?.phone !== undefined ? (
                <a href={"tel:" + data?.phone} className="hover:underline ">
                  {data?.phone}
                </a>
              ) : (
                <div className="w-40">
                  <Skeleton className="animate-pulse" />
                </div>
              )}
            </div>
          </div>

          <div className="tracking-tight text-gray-900 dark:text-gray-200">
            <div className="inline-flex gap-4  items-center w-full">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <PriceComponent evseID={evseID} children={t("price:info")} />
            </div>
          </div>
        </div>

        <div className="flex justify-between flex-row pt-8">
          <div className="mx-1 px-12"></div>

          <InfoPopup />

          <Link type="button" to="tariff">
            <button
              type="button"
              className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {t("common:next")} &nbsp;
              <svg
                className="w-3 h-3 text-white mr-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                />
              </svg>
            </button>
          </Link>
        </div>
      </HelmetProvider>
    </>
  );
};
export default DetailsPage;
