import React, { useContext, useEffect } from "react";
import { AppCtx } from "../commom/app.context";
import formatHTML from "../components/helpers";
import LoaderComponent from "../components/loader.component";
import { useTranslation } from "react-i18next";

const AGBPopup: React.FC = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation(["common", "data_protection"]);

  const [showModal, setShowModal] = React.useState(false);

  const [content, setContent] = React.useState(
    <div className="text-center">
      <LoaderComponent />
    </div>
  );

  const appCtx = useContext(AppCtx);

  useEffect(() => {
    try {
      if (!appCtx?.adhocText) {
        return;
      }

      // get the adhoc text from the app context
      if (appCtx?.adhocText?.en?.agb === undefined) {
        throw new Error("AGB text is undefined");
      }

      let adhocText: string;

      if (language.toLowerCase().includes("en")) {
        adhocText = appCtx?.adhocText?.en?.privacy;
      } else {
        adhocText = appCtx?.adhocText?.de?.privacy;
      }

      let jsonArray = adhocText.split("\n");
      //
      let formatted = jsonArray.map((texts: string, i: number) => formatHTML(texts, i));

      // wrap the formatted text in a div
      setContent(<>{formatted}</>);
    } catch (e) {
      let err = t("common:failed_to_load_data");
      // wrap the formatted text in an alert div
      setContent(
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">{err}</strong>
          <span className="block sm:inline">{err}</span>
        </div>
      );
    }
  }, [appCtx]);

  const handleClick = async (val: boolean) => {
    setShowModal(val);
  };
  return (
    <>
      {appCtx?.connector?.cpoName === undefined ? (
        <button type="button" className="no-underline hover:underline text-gray-800 dark:text-blue-300 font-bold">
          {t("data_protection:title")}
        </button>
      ) : (
        <button
          type="button"
          onClick={() => handleClick(true)}
          className="no-underline hover:underline text-gray-800 dark:text-blue-300 font-bold"
        >
          {t("data_protection:title")}
        </button>
      )}

      {showModal ? (
        <>
          <div
            id="defaultModal"
            aria-hidden="true"
            className="mt-2 max-w-lg overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-center md:inset-0 h-modal sm:h-full"
          >
            <div className="relative px-4 w-full max-w-2xl h-full md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">{t("data_protection:title")}</h3>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6">{content}</div>
                <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                  <button
                    data-modal-toggle="defaultModal"
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default AGBPopup;
