import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

const CookieAlert: React.FC = () => {
  const { t } = useTranslation(["common", "cookie"]);

  const [showCookieAlert, setCookieAlert] = useState(false);

  const [cookies, setCookie] = useCookies(["accepted_cookie"]);

  useEffect(() => {
    if (!cookies["accepted_cookie"] || cookies["accepted_cookie"] === false) {
      setCookieAlert(true);
    } else {
      setCookieAlert(false);
    }
  }, []);

  const handleClick = (val: boolean) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);

    setCookie("accepted_cookie", val, { expires: expirationDate });

    setCookieAlert(!val);
  };

  return (
    <div className={showCookieAlert ? " absolute bottom-0 block p-6 max-w-md shadow rounded-md bg-blue-600 " : "hidden"}>
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-white ">{t("cookie:title")}</h5>

      <p className="font-normal text-gray-100">{t("cookie:description")}</p>

      <button type="button" onClick={() => handleClick(true)} className="no-underline hover:underline text-white font-bold mt-2">
        {t("cookie:accept")}
      </button>
    </div>
  );
};
export default CookieAlert;
